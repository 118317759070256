import React, { useRef, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { Layout } from '../shared/components/layout/Layout';
import { useIsMobileDisplay } from '../shared/hooks/useIsMobileDisplay';
import { useEffect } from 'react';

interface QueryResult {
  homeVideoUrl: {
    frontmatter: {
      laptopUrl: string;
      mobileUrl: string;
      posterUrl: string;
    };
  };
}

const Home: React.FC<PageProps<QueryResult>> = ({ data }) => {
  const isMobileDisplay = useIsMobileDisplay();

  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [isMobileDisplay]);

  const videoUrl = isMobileDisplay
    ? data.homeVideoUrl.frontmatter.mobileUrl
    : data.homeVideoUrl.frontmatter.laptopUrl;

  return (
    <Layout headerTransition>
      <video
        muted
        autoPlay
        loop
        preload="metadata"
        className="fixed top-0 left-0 bottom-0 object-cover h-full w-full"
        ref={videoRef}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  query IndexPageQuery {
    homeVideoUrl: markdownRemark(
      fileAbsolutePath: { regex: "/homeVideos/homevideos.md/" }
    ) {
      frontmatter {
        laptopUrl
        mobileUrl
        posterUrl
      }
    }
  }
`;
